var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "competences" },
    [
      _c("t-page-header", {
        attrs: {
          title: _vm.formattedTitle,
          actionsLeftAligned: true,
          rightSize: ""
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass: "ui icon basic button go-back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "long-arrow-alt-left"] }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "description",
            fn: function() {
              return [
                !_vm.isLoading
                  ? _c(
                      "div",
                      { staticClass: "breadcrumb-info" },
                      [
                        _c("router-link", {
                          staticClass: "link name",
                          attrs: {
                            to: {
                              name: "donors.single",
                              params: { id: _vm.competenceDetail.donorId }
                            }
                          },
                          domProps: {
                            innerHTML: _vm._s(_vm.competenceDetail.donorName)
                          }
                        }),
                        _c(
                          "span",
                          { staticClass: "indicator" },
                          [
                            _c("t-icon", {
                              staticClass: "icon",
                              attrs: { icon: ["fa", "angle-right"] }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          staticClass: "text amount",
                          domProps: {
                            textContent: _vm._s(
                              "Doação de BRL " + _vm.monthlyAmount + " mensais"
                            )
                          }
                        })
                      ],
                      1
                    )
                  : _c("t-placeholder", { attrs: { lines: 1 } })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          false ? _c("t-page-sidebar") : _vm._e(),
          _c(
            "t-page-content",
            { attrs: { size: "sixteen" } },
            [
              _vm.competences.length === 0 && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma competência",
                      subheader: "Não há competências"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-competences-table", {
                        attrs: { loading: _vm.isLoading },
                        on: { orderBy: _vm.orderBy }
                      }),
                      _vm.totalPages > 1 && !_vm.isLoading
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }