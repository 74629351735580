var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass:
            "session-expired ui page dimmer transition visible active"
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("t-icon", {
                staticClass: "icon",
                attrs: { icon: ["far", "clock"] }
              }),
              _c("h1", { staticClass: "header" }, [_vm._v("Sessão Expirada!")]),
              _c("h2", { staticClass: "sub header" }, [
                _vm._v("Faça login novamente!")
              ]),
              _c(
                "button",
                {
                  staticClass: "ui button primary btn-logout",
                  on: { click: _vm.logoutUser }
                },
                [_vm._v("Login")]
              )
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }