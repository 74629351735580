<template>
  <div class="wrap-pagination">
    <div class="wrap-drop">
      <div ref="pageInput"
        class="tui ui input">
        <input type="number" name="pagination"
          :value="currentPage"
          @keyup.enter="validatePage($event.target.value)"
          class="current-page"
          maxlength="2">
        <span class="text total-pages">de {{totalPages}}</span>
      </div>
    </div>

    <div class="ui basic buttons">
      <button class="ui icon button" @click="btnClickPrev">
        <t-icon :icon="[ 'fas', 'angle-left' ]" />
      </button>
      <button class="ui icon button" @click="btnClickNext">
        <t-icon :icon="[ 'fas', 'angle-right' ]" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    totalPages: {
      type: String
    },
    currentPage: {
      type: String
    },
    pagesSelected: {
      type: String,
      default: '50'
    },
    btnClickPrev: {
      type: Function
    },
    btnClickNext: {
      type: Function
    },
    goToPage: {
      type: Function
    },
    min: {
      type: Number,
      default: 1
    }
  },
  methods: {
    validatePage (value) {
      if ( value < this.min || value > parseInt(this.totalPages) ) {
        this.$refs.pageInput.classList.add('error')
      } else {
        this.$refs.pageInput.classList.remove('error')
        return this.goToPage(value)
      }
    }
  },

  updated () {
    this.$refs.pageInput.classList.remove('error')
  }
}
</script>

<style lang="scss" scoped>

  .wrap-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .text {
      margin: 5px 15px;
    }

    .total-pages {
      color: #a7a7a7
    }

    .ui.icon.button {
      padding: 7px 12px;
    }

    .current-page {
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      width: 30px;
      height: 30px;
      text-align: center;
      margin: 0px;
      padding: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }
</style>
