var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "donation" },
    [
      _c("t-page-header", {
        attrs: {
          actionsLeftAligned: true,
          rightSize: "",
          title: _vm.headerTitle,
          popup: _vm.popup
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function() {
              return [
                !_vm.isLoadingDetail
                  ? _c(
                      "div",
                      { staticClass: "donation-customer-info" },
                      [
                        _c("router-link", {
                          staticClass: "link name",
                          attrs: {
                            to: {
                              name: "donors.single",
                              params: { id: _vm.donorId }
                            }
                          },
                          domProps: { innerHTML: _vm._s(_vm.donorName) }
                        }),
                        _c(
                          "span",
                          { staticClass: "indicator" },
                          [
                            _c("t-icon", {
                              staticClass: "icon",
                              attrs: { icon: ["fa", "angle-right"] }
                            })
                          ],
                          1
                        ),
                        _c("span", {
                          staticClass: "text amount",
                          domProps: { textContent: _vm._s(_vm.donationAmount) }
                        })
                      ],
                      1
                    )
                  : _c("t-placeholder", { attrs: { lines: 1 } })
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                false
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "tui tuim ui positive pointing top right dropdown icon button"
                      },
                      [
                        _c("span", [_vm._v("Doação ativa")]),
                        _c("t-icon", {
                          staticClass: "tui tuim icon",
                          attrs: { icon: ["fas", "caret-down"] }
                        }),
                        _c("div", { staticClass: "menu" }, [
                          _c("div", { staticClass: "item" }, [
                            _c("i", { staticClass: "edit icon" }),
                            _vm._v(" Edit Post")
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("i", { staticClass: "delete icon" }),
                            _vm._v(" Remove Post")
                          ]),
                          _c("div", { staticClass: "item" }, [
                            _c("i", { staticClass: "hide icon" }),
                            _vm._v(" Hide Post")
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                false
                  ? _c(
                      "button",
                      { staticClass: "tui tuim ui basic button" },
                      [
                        _c("t-icon", {
                          staticClass: "tui tuim icon",
                          attrs: { icon: ["fas", "save"] }
                        }),
                        _vm._v("\n        Salvar Alterações\n      ")
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "ui icon basic button go-back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "long-arrow-alt-left"] }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "notifications",
            fn: function() {
              return [_c("t-notification")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        { attrs: { customClassRow: "centered" } },
        [
          _c(
            "t-page-content",
            { attrs: { size: "eight" } },
            [
              _c("h2", [_vm._v("Dados da doação")]),
              !_vm.isLoadingDetail
                ? _c(
                    "div",
                    { staticClass: "tui tuim ui card donation-status" },
                    [
                      _c(
                        "t-alert",
                        {
                          attrs: {
                            icon: "check",
                            type: _vm.donationDetail.status,
                            show: ""
                          }
                        },
                        [
                          _c("h3", {
                            staticClass: "donation status label",
                            domProps: {
                              textContent: _vm._s(
                                _vm.donationDetail.statusLabel
                              )
                            }
                          }),
                          _vm.finishedDonation(_vm.donationDetail.status)
                            ? _c("p", {
                                domProps: {
                                  textContent: _vm._s(
                                    "Finalizada em: " +
                                      _vm.formatDate(
                                        _vm.donationDetail.finishedAt
                                      )
                                  )
                                }
                              })
                            : _c("p", {
                                domProps: {
                                  textContent: _vm._s(
                                    "Criada em: " +
                                      _vm.formatDate(
                                        _vm.donationDetail.createdAt
                                      )
                                  )
                                }
                              }),
                          false
                            ? _c("p", {
                                domProps: {
                                  textContent: _vm._s(
                                    "Ativa desde: " +
                                      _vm.formatDate(
                                        _vm.donationDetail.createdAt
                                      )
                                  )
                                }
                              })
                            : _vm._e()
                        ]
                      ),
                      false
                        ? _c(
                            "div",
                            { staticClass: "content spacer" },
                            [
                              _c("t-status-color-bar", {
                                attrs: {
                                  status: "7/10 sucessos",
                                  items: [
                                    { width: "65%", background: "#b4db75" },
                                    { width: "15%", background: "#f9d96a" },
                                    { width: "15%", background: "#fd9093" },
                                    { width: "5%", background: "#e2e2e3" }
                                  ]
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isLoadingDetail
                ? _c("t-card-donation-detail", {
                    staticClass: "donation-detail"
                  })
                : _c("t-card-placeholder", {
                    staticClass: "donation-detail placeholder",
                    attrs: { title: "Dados da doação", placeholder: 6 }
                  }),
              !_vm.isLoadingDetail
                ? _c("t-form-billing-donation", {})
                : _c("t-card-placeholder", {
                    staticClass: "placeholder",
                    attrs: { title: "Dados de cobrança", placeholder: 6 }
                  })
            ],
            1
          ),
          _c(
            "t-page-sidebar",
            { attrs: { size: "four", customClass: "row" } },
            [
              _c(
                "t-group-cards",
                {
                  staticClass: "billing-history",
                  attrs: { title: "Histórico de cobranças" },
                  scopedSlots: _vm._u(
                    [
                      false
                        ? {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  { staticClass: "ui fluid button" },
                                  [
                                    _c("t-icon", {
                                      attrs: { icon: ["fas", "sync-alt"] }
                                    }),
                                    _vm._v(
                                      "\n            Carregar mais\n          "
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                _vm._l(_vm.competences, function(item, i) {
                  return _c(
                    "router-link",
                    {
                      key: i,
                      attrs: {
                        to: {
                          name: "competences",
                          params: { competenceId: item.id }
                        }
                      }
                    },
                    [
                      _c("t-group-history-item", {
                        attrs: {
                          status: item.status,
                          statusLabel: item.statusLabel,
                          date: _vm.textFormatDate(item.originalDueDate),
                          statusCode: ""
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }