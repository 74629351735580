var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-register" },
    [
      _c("t-icon", {
        staticClass: "clock-icon",
        attrs: { icon: ["fas", "exclamation-circle"] }
      }),
      _c("h1", {
        staticClass: "header",
        domProps: { textContent: _vm._s(_vm.header) }
      }),
      _c("h2", {
        staticClass: "sub header",
        domProps: { textContent: _vm._s(_vm.subheader) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }