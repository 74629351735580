var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass:
            "coming-soon-modal ui page dimmer transition visible active"
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("t-icon", { attrs: { icon: ["fas", "clock"] } }),
              _c("h1", { staticClass: "header" }, [
                _vm._v("Funcionalidade ainda não disponível!")
              ]),
              _c("h2", { staticClass: "sub header" }, [
                _vm._v(
                  "Aguarde as próximas versões! Em breve essa funcionalidade estará disponível para você!"
                )
              ])
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }