var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tui ui accordion" }, [
    _c(
      "div",
      { staticClass: "wrap-accordion" },
      [
        _c(
          "div",
          {
            staticClass: "title",
            class: { active: _vm.activeStatus },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.activeStatus = !_vm.activeStatus
              }
            }
          },
          [
            _c("h3", { staticClass: "inner-title" }, [
              _c("span", { staticClass: "file-status" }, [
                _vm._v(
                  _vm._s(
                    _vm.$tc(
                      _vm.fileStatus[0],
                      _vm.fileStatus.length > 1 ? _vm.fileStatus[1] : 1
                    )
                  )
                )
              ]),
              _vm.showTotal
                ? _c("span", { staticClass: "file-total" }, [
                    _vm._v("(" + _vm._s(_vm.total) + ")")
                  ])
                : _vm._e()
            ]),
            _c("t-icon", {
              staticClass: "icon",
              attrs: {
                icon: [
                  "far",
                  !_vm.activeStatus ? "plus-square" : "minus-square"
                ]
              }
            })
          ],
          1
        ),
        _c("transition", { attrs: { name: "fade" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStatus,
                  expression: "activeStatus"
                }
              ]
            },
            [
              _c(
                "table",
                { staticClass: "ui basic table", class: _vm.fileStatus[0] },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _vm._l(_vm.thead, function(head, i) {
                          return _c(
                            "th",
                            {
                              key: i,
                              staticClass: "table-header",
                              class: _vm.getHeadClass(
                                head.name,
                                _vm.fileStatus[0]
                              )
                            },
                            [_vm._v(_vm._s(head.label))]
                          )
                        }),
                        _vm.extraOptions ? _c("th", [_vm._v(" ")]) : _vm._e()
                      ],
                      2
                    )
                  ]),
                  _c(
                    "tbody",
                    { staticClass: "basic-table-body" },
                    [
                      _vm.loading
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                staticClass: "table-lines",
                                attrs: { colspan: _vm.thead.length }
                              },
                              [_c("t-placeholder", { attrs: { lines: 1 } })],
                              1
                            )
                          ])
                        : _vm._l(_vm.filesList, function(items, i) {
                            return _c(
                              "tr",
                              { key: i, staticClass: "table-lines" },
                              [
                                _vm._l(items, function(item, k) {
                                  return _c(
                                    "td",
                                    {
                                      key: k,
                                      class: _vm.solveColumnItem(item, k)
                                    },
                                    [
                                      _c("span", {
                                        domProps: { innerHTML: _vm._s(item) }
                                      })
                                    ]
                                  )
                                }),
                                _vm.extraOptions
                                  ? _c(
                                      "td",
                                      {
                                        staticClass:
                                          "center aligned extra-options"
                                      },
                                      [
                                        _c("t-dropdown", {
                                          attrs: {
                                            icon: ["fas", "ellipsis-h"],
                                            extraClass: "top right",
                                            pointing: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "items",
                                                fn: function() {
                                                  return _vm._l(
                                                    _vm.extraOptionsItems,
                                                    function(item, i) {
                                                      return _c("router-link", {
                                                        key: i,
                                                        staticClass: "item",
                                                        attrs: {
                                                          tag: item.tag,
                                                          to: item.action
                                                        },
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.description
                                                          )
                                                        }
                                                      })
                                                    }
                                                  )
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          })
                    ],
                    2
                  )
                ]
              )
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }