var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "upload-file" }, [
      _c(
        "label",
        { staticClass: "input-file-wrap" },
        [
          _c("input", {
            ref: "file",
            staticClass: "input-file",
            attrs: { type: "file" },
            on: { change: _vm.handleFileUpload }
          }),
          _vm._v("\n      " + _vm._s(_vm.$t("ImportReturn")) + "\n      "),
          _c("t-icon", { attrs: { icon: ["fas", "file-upload"] } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }