var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: {
          title: "Boletos Registrados",
          leftSize: "nine",
          rightSize: "seven",
          popup:
            "<div class='content'><p>Aqui você pode visualizar todos os boletos registrados.</p></div>"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "actions",
              fn: function() {
                return [
                  _c("div", { staticClass: "files-header-options" }, [
                    _c(
                      "button",
                      { staticClass: "tui ui basic button" },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.exportButtonText) }
                        }),
                        _c("t-icon", {
                          staticClass: "icon amount-selected",
                          attrs: { icon: ["fas", "file-download"] }
                        })
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            },
            false
              ? {
                  key: "bottom",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            { attrs: { customClass: "full-bg" } },
            [
              _c("t-filter", {
                ref: "filterFilesBankSlip",
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "filter-fields" }, [
                          _c("label", [_vm._v("Data de")]),
                          _c(
                            "div",
                            { staticClass: "sixteen field wrap-form-inline" },
                            [
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "tui tuim ui search dropdown left-side month",
                                  attrs: { placeholder: "Escolha o mês" }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Mês")
                                  ]),
                                  _vm._l(_vm.months, function(month, i) {
                                    return _c("option", {
                                      key: i,
                                      domProps: {
                                        value: month.value,
                                        textContent: _vm._s(month.name)
                                      }
                                    })
                                  })
                                ],
                                2
                              ),
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "tui tuim ui search dropdown right-side year",
                                  attrs: { placeholder: "Escolha o ano" }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Ano")
                                  ]),
                                  _vm._l(_vm.years, function(year, i) {
                                    return _c("option", {
                                      key: i,
                                      domProps: {
                                        value: year,
                                        textContent: _vm._s(year)
                                      }
                                    })
                                  })
                                ],
                                2
                              )
                            ]
                          ),
                          _c("label", [_vm._v("Data até")]),
                          _c(
                            "div",
                            { staticClass: "sixteen field wrap-form-inline " },
                            [
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "tui tuim ui search dropdown left-side month",
                                  attrs: { placeholder: "Escolha o mês" }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Mês")
                                  ]),
                                  _vm._l(_vm.months, function(month, i) {
                                    return _c("option", {
                                      key: i,
                                      domProps: {
                                        value: month.value,
                                        textContent: _vm._s(month.name)
                                      }
                                    })
                                  })
                                ],
                                2
                              ),
                              _c(
                                "select",
                                {
                                  staticClass:
                                    "tui tuim ui search dropdown right-side year",
                                  attrs: { placeholder: "Escolha o ano" }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("Ano")
                                  ]),
                                  _vm._l(_vm.years, function(year, i) {
                                    return _c("option", {
                                      key: i,
                                      domProps: {
                                        value: year,
                                        textContent: _vm._s(year)
                                      }
                                    })
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ])
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              _vm.bankslips.length === 0 && !_vm.isLoaging
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma transação",
                      subheader: "Não há transações"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-bankslip-table", {
                        attrs: { bankslips: _vm.bankslips }
                      }),
                      _vm.totalPages > 1
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }