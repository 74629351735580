var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.widgets.length > 0
    ? _c(
        "div",
        { staticClass: "ui accordion year-wrap" },
        [
          _c(
            "div",
            {
              staticClass: "title",
              class: { active: _vm.activeYear },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.activeYear = !_vm.activeYear
                }
              }
            },
            [
              _c("span", { staticClass: "year" }, [_vm._v(_vm._s(_vm.year))]),
              _c("t-icon", {
                staticClass: "icon",
                attrs: {
                  icon: [
                    "far",
                    !_vm.activeYear ? "plus-square" : "minus-square"
                  ]
                }
              })
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeYear,
                    expression: "activeYear"
                  }
                ]
              },
              [
                _c("table", { staticClass: "ui basic table" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "head-line" }, [
                      _c("th", { staticClass: "month-title" }),
                      _c("th", { staticClass: "center aligned inner-title" }, [
                        _vm._v("Novos")
                      ]),
                      _c("th", { staticClass: "center aligned inner-title" }, [
                        _vm._v("Sem Retorno")
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm.isLoading
                        ? _c("tr", [
                            _c(
                              "td",
                              [
                                _c("t-placeholder", {
                                  attrs: {
                                    lines: 1,
                                    header: true,
                                    paragraph: false
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _c("t-placeholder", {
                                  attrs: { lines: 2, paragraph: true }
                                })
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _c("t-placeholder", {
                                  attrs: { lines: 2, paragraph: true }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._l(_vm.filteredWidgets, function(widget, key) {
                            return _c(
                              "tr",
                              {
                                key: key,
                                staticClass: "body-line",
                                class: _vm.widgetClasses(
                                  _vm.currentMonty,
                                  widget.month,
                                  _vm.currentYear,
                                  _vm.year,
                                  _vm.loading
                                ),
                                on: {
                                  click: function($event) {
                                    return _vm.toMonth(
                                      $event,
                                      widget.month,
                                      _vm.year
                                    )
                                  }
                                }
                              },
                              [
                                _c("td", [
                                  _c("h3", {
                                    staticClass: "months",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.getMonth(widget.month)
                                      )
                                    }
                                  })
                                ]),
                                _c("td", { staticClass: "news" }, [
                                  widget.new !== "" &&
                                  widget.new !== 0 &&
                                  widget.new !== null
                                    ? _c("strong", {
                                        domProps: {
                                          textContent: _vm._s(widget.new)
                                        }
                                      })
                                    : _c("strong", {
                                        staticClass: "empty-value"
                                      })
                                ]),
                                _c("td", { staticClass: "without-return" }, [
                                  widget.withoutReturn !== "" &&
                                  widget.withoutReturn !== 0 &&
                                  widget.withoutReturn !== null
                                    ? _c("strong", {
                                        domProps: {
                                          textContent: _vm._s(
                                            widget.withoutReturn
                                          )
                                        }
                                      })
                                    : _c("strong", {
                                        staticClass: "empty-value"
                                      })
                                ])
                              ]
                            )
                          })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }