var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transference-single" },
    [
      _c("t-page-header", {
        attrs: {
          leftSize: "fifteen",
          rightSize: "one",
          title: _vm.formattedTitle,
          actionsLeftAligned: true
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass: "ui icon basic button go-back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["fas", "long-arrow-alt-left"] }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "innerLeftActions",
            fn: function() {
              return [
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "tui tuim ui basic button",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.callCreateReport()
                        }
                      }
                    },
                    [
                      _c("t-icon", {
                        staticClass: "tui tuim icon",
                        attrs: { icon: "file" }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("ExportTo", { to: ".xlsx" })) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "notifications",
            fn: function() {
              return [_c("t-notification")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            [
              _c("t-filter", {
                ref: "filterTransferences",
                attrs: {
                  applyFilter: _vm.applyFilter,
                  form: _vm.filterForm,
                  filterItems: _vm.filterItems,
                  filterUpdated: _vm.filtersUpdated
                },
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [_c("t-transference-filters")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              _vm.transferenceTransactions.length === 0 && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma Transação",
                      subheader: "Não há transações"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("tui-table", {
                        attrs: {
                          isLoading: _vm.isLoading,
                          headers: _vm.tableHeader,
                          dataList: _vm.transferenceTransactions,
                          dataFormatter: _vm.formatData,
                          routeParams: _vm.routeParams,
                          sortable: ""
                        },
                        on: { orderBy: _vm.orderBy }
                      }),
                      _vm.totalPages > 1 && !_vm.isLoading
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "modalReportTransference",
          staticClass: "ui tiny modal confirm-report"
        },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v("Confirmar exportação")
          ]),
          _c("div", { staticClass: "content" }, [
            _vm._m(0),
            _vm._m(1),
            _c("p", [
              _c("span", {
                staticClass: "user-email",
                domProps: { textContent: _vm._s(_vm.user.email) }
              })
            ])
          ]),
          _c("div", { staticClass: "actions" }, [
            _c("span", {
              staticClass: "ui cancel button",
              domProps: { textContent: _vm._s("Cancelar") }
            }),
            _c("span", {
              staticClass: "ui approve primary button",
              domProps: { textContent: _vm._s("Exportar") },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.generateReport()
                }
              }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("p", [_vm._v("Tem certeza que deseja exportar esse relatório?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(
          "O processo de exportação pode demorar alguns minutos.  O arquivo será enviado\n          para o e-mail abaixo assim que concluído."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }